@charset "utf-8";

@import "so-simple/functions";
@import "so-simple/variables";
@import "so-simple/notices";

/* Colors */
$base-color: #eaeaea !default;
$text-color: #eaeaea !default;
$accent-color: #FFADCA !default;
$nav-color: #aeaeae !default;
$background-color: #252a34 !default;
$nav-background-color: mix(#000, #ff4d8b, 30%) !default;
$code-background-color: mix(#000, #ff4d8b, 15%) !default;
$border-color: tint($base-color, 80%) !default;

$primary-color: white !default;
$success-color: rgb(48, 209, 88) !default;
$warning-color: rgb(255, 214, 10) !default;
$danger-color: rgb(255, 69, 58) !default;
$info-color: rgb(100, 210, 255) !default;

$base00: #263238;
$base01: #2e3c43;
$base02: #314549;
$base03: #546e7a;
$base04: #b2ccd6;
$base05: #eeffff;
$base06: #eeffff;
$base07: #ffffff;
$base08: #f07178;
$base09: #f78c6c;
$base0a: #ffcb6b;
$base0b: #c3e88d;
$base0c: #89ddff;
$base0d: #82aaff;
$base0e: #c792ea;
$base0f: #ff5370;

@import "so-simple/skin";